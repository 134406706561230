<template>
  <div class="relative">
    <v-field
      active
      :class="{ 'v-field--error': errorMessage }"
      :label="label"
      variant="outlined"
    >
      <input
        ref="picker"
        class="w-full p-3 outline-none"
        :type="type"
        :value="inputValue"
        @click="onClick"
        @input="onChange(($event.target as HTMLInputElement | null)?.value)"
      />
    </v-field>

    <v-slide-x-reverse-transition>
      <span
        v-if="errorMessage"
        class="text-error absolute -bottom-4 pl-3 text-xs"
        >{{ errorMessage }}</span
      >
    </v-slide-x-reverse-transition>
  </div>
</template>

<script lang="ts" setup>
import { formatDate } from "@vueuse/core";
import { useField } from "vee-validate";

const properties = defineProps({
  ...appFormInputProperties,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue: { type: String as any, required: false, default: undefined },
  type: {
    type: String,
    default: "datetime-local",
  },
});

const inputValue = computed(() => {
  if (!properties.modelValue) return;

  return formatDate(
    new Date(properties.modelValue as string),
    properties.type === "date" ? "YYYY-MM-DD" : "YYYY-MM-DDTHH:mm",
  );
});

const emit = defineEmits<{
  (event: "update:modelValue", payload?: string): void;
}>();

const { value, errorMessage, handleChange } = useField(
  properties.name ?? properties.label ?? uniqueFieldName(),
  computed(() => {
    return {
      required: properties.required,
      ...properties.rules,
    };
  }),
  {
    syncVModel: false,
    initialValue: properties.modelValue,
    validateOnValueUpdate: false,
  },
);

function onChange(input: string | undefined) {
  const isoDate = input ? new Date(input).toISOString() : undefined;

  handleChange(isoDate);
  emit("update:modelValue", isoDate);
}

watch(
  () => properties.modelValue,
  (date) => (value.value = date),
);

const pickerElement = templateRef<HTMLInputElement>("picker");

const onClick = () => pickerElement.value?.showPicker();
</script>
